<template>
  <div>
    <v-card outlined>
      <v-card-text class="pa-6">
        <div class="d-flex py-4">
          <div>
            <app-text-field label="Nome do Documento" v-model="form.name" />
          </div>
          <div>
            <app-select :items="typeOptions" v-model="form.type" label="Tipo" />
          </div>
        </div>

        <div class="py-1">
          <v-alert color="grey lighten-4">
            <v-row>
              <v-col class="pb-0" cols="12">
                Utilize as tags abaixo para que sua mensagem seja autocompletada
                pelo sistema
              </v-col>
              <v-col>
                <small>
                  {nome_{{ customer_alias }}}, {rg_{{ customer_alias }}},
                  {cpf_{{ customer_alias }}}, {meu_contato}, {link_meu_contato},
                  {valor}, {valor_extenso}, {nome_profissional},
                  {registro_do_conselho},{numero_de_sessoes},
                  {numero_de_sessoes_extenso}, {data_pagamento}
                  ,{descricao},{data_de_hoje}, {data_de_hoje_extenso},
                  {cpf_responsavel_financeiro}, {nome_responsavel_financeiro},
                  {whatsapp_responsavel_financeiro},
                  {email_responsavel_financeiro}, {rg_responsavel_financeiro},
                  {detalhes}
                </small>
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <div>
          <AppEditor v-model="form.text" :autocomplete="true" />
        </div>
        <br />
        <div class="d-flex justify-end">
          <div v-if="!isInsert" class="px-3">
            <app-delete-btn @click="handleDelete()" rounded />
          </div>

          <div class="px-3">
            <v-btn @click="handleBack()" rounded outlined>Voltar</v-btn>
          </div>

          <div class="px-3">
            <v-btn @click="handleSave()" rounded color="primary">Salvar</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";
import AppEditor from "@/components/app/forms/AppEditor";

export default {
  components: {
    AppEditor,
  },

  data() {
    return {
      typeOptions: [
        { value: "receipt", text: "Recibos" },
        { value: "customer_document", text: "Documentos do Cliente/Paciente" },
        { value: "session_document", text: "Documentos da Sessão" },
      ],

      form: {
        text: null,
        name: null,
        type: 0,
      },
    };
  },

  mounted() {
    this.show();
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    customer_alias() {
      return customerAlias().toLowerCase();
    },
  },

  methods: {
    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("document/document", this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    show() {
      if (this.$route.params.id > 0) {
        this.$loading.start();
        this.$http
          .show("document/document", this.$route.params.id)
          .then((response) => {
            this.form = response.document;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },
    handleBack() {
      this.$router.push("/configuracoes/documentos");
    },
    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy("document/document", this.form.id)
        .then((response) => {
          this.$loading.finish();
          this.select();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    update() {
      this.$loading.start();
      this.$http
        .update("document/document", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$loading.finish();
      this.$router.push("/configuracoes/documentos");
    },
  },
};
</script>

<style></style>
