<template>
  <div>
    <template v-if="isMobile"> <MobileForm /> </template>
    <template v-if="!isMobile"><DesktopForm /> </template>
  </div>
</template>

<script>
import MobileForm from "@/components/documents/forms/MobileForm.vue";
import DesktopForm from "@/components/documents/forms/DesktopForm.vue";

export default {
  components: { MobileForm, DesktopForm },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
};
</script>

<style>
</style>